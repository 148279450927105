import React from "react"
import { graphql, Link } from "gatsby"
import SeoHead from "../components/global/seoHead"
import Layout from "../components/global/layout"
import ScrollIndicationHeightZero from "../components/global/scrollIndicationHeightZero"
import Seo from "../components/global/seo"
import BlogList from '../components/blog/blogList'
import { Container, Row, Col } from "react-bootstrap"

import blogCategoryFeaturedImage from "../../static/home/wowmakers.jpeg"

export const Head = ({ data: { category } }) => (
  <>
    <SeoHead
      ogImage={blogCategoryFeaturedImage}
      title={`Articles and videos related to ` + category.name + ` - WowMakers blog`}
      description="We put together a whole bunch of articles, videos, resources, and blogs to help you understand the UX design, UI design, UX design process, UX research, ROI of UX, and lot more"
    />
  </> 
)

const BlogCategoryTemplate = ({ data: { category } }) => {
    return (
        <Layout>
          <Seo title="Blog Category" bclass="blog-category"/>
          <ScrollIndicationHeightZero />
          <div className="blog-header">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <p class="text-center cat-header">
                          <Link to="/blog/" className="all-blog">All Blog</Link>
                        </p>          
                        <h1 class="text-center">{category.name}</h1>
                    </Col>
                </Row>
            </Container>
          </div>
          <div className="blog-listing">
            <Container>
                <Row>
                {category.posts.nodes.map((node, index) => (
                    <BlogList key={node.id} node={node} index={index}/>
                ))}          
                </Row>
            </Container>
          </div>          
        </Layout>
      )
}

export default BlogCategoryTemplate

export const pageQuery = graphql`
  query CategoryPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    category: wpCategory(id: { eq: $id }) {
      id
      uri
      name
      posts {
        nodes {
          title
          uri
          slug
          content
          date(formatString: "MMM DD, YYYY")
          relPost {
            externalLink
          }
          categories {
            nodes {
              name
              uri
            }
          }          
          author {
            node {
              name
              usersAcf {
                designation
                uploadProfileImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, width: 35, height: 35, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: AUTO, width: 386, height: 216, placeholder: BLURRED)
                }
              }
            }
          }          

        }
      }        
    }
  }
`